<template>
  <button class="main-btn" @click="clicked">
    <slot name="icon" @click="clicked" />

    {{ btnText }}
  </button>
</template>

<script>
export default {
  name: "PageButton",
  props: {
    to: {
      required: false,
      type: String,
    },
    btnText: {
      required: false,
    },
  },
  methods: {
    clicked() {
      if (this.to && this.$route.path !== this.to) {
        this.$router.push({ path: this.to });
      }
      this.$emit("click");
    },
  },
};
</script>

<style scoped></style>
