<template>
  <div class="rules">
    <PageButton @click="goBack">
      <template v-slot:icon>
        <Close />
      </template>
    </PageButton>

    <div class="logo">
      <img src="@/assets/img/svg/logo.svg" alt="" />
    </div>

    <div class="rules__title">
      {{ $t("rules-title") }}
    </div>

    <ul class="rules-list">
      <li class="rules-list__item">
        <div class="rules-list__icon">
          <ListIconSvg />
        </div>

        <div class="rules-list__content">
          <div class="rules-list__title">{{ $t("rules-item-title") }} 01</div>

          <div class="default-text">
            <p>{{ $t("rules-text-1") }}</p>
            <p>{{ $t("rules-text-2") }}</p>
          </div>
        </div>
      </li>
      <li class="rules-list__item">
        <div class="rules-list__icon">
          <ListIconSvg />
        </div>

        <div class="rules-list__content">
          <div class="rules-list__title">{{ $t("rules-item-title") }} 02</div>

          <div class="default-text">
            <p>{{ $t("rules-text-3") }}</p>
          </div>
        </div>
      </li>
      <li class="rules-list__item">
        <div class="rules-list__icon">
          <ListIconSvg />
        </div>

        <div class="rules-list__content">
          <div class="rules-list__title">{{ $t("rules-item-title") }} 03</div>

          <div class="default-text">
            <p>{{ $t("rules-text-4") }}</p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import PageButton from "../components/common/buttons/PageButton";
import Close from "../components/icons/Close";
import ListIconSvg from "../components/icons/ListIconSvg";
import GoBackMixin from "@/mixins/common/GoBackMixin";
export default {
  name: "RulesPage",
  mixins: [GoBackMixin],
  components: { ListIconSvg, Close, PageButton },
};
</script>

<style scoped></style>
