export default {
  methods: {
    goBack() {
      if (this.$route.name === "Game") {
        this.$router.push({
          name: "Events",
        });
        return;
      }
      this.$router.go(-1);
    },
  },
};
