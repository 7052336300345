<template>
  <svg
    width="11"
    height="12"
    viewBox="0 0 11 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.90024 10.5299C3.38024 10.0099 4.12025 10.0499 4.54025 10.6199L5.14026 11.4199C5.61026 12.0499 6.39023 12.0499 6.86023 11.4199L7.45026 10.6299C7.87026 10.0699 8.61024 10.0299 9.09024 10.5399C10.1402 11.6599 10.9902 11.2899 10.9902 9.71988V3.08988C10.9902 0.719882 10.4302 0.129883 8.21024 0.129883H3.77023C1.55023 0.129883 0.990234 0.719882 0.990234 3.08988V9.71988C1.00023 11.2699 1.86024 11.6399 2.90024 10.5299Z"
      fill="#9984D4"
    />
  </svg>
</template>

<script>
export default {
  name: "ListIconSvg",
};
</script>

<style scoped></style>
